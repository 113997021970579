import React from 'react';
import {
  CodeSnippet,
  Link as DocLink,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Link, AvatarUser } from 'hudl-uniform-ui-components';

const linkSnippet = require('raw-loader!../../../../data/snippets/link.example');
const wrapperSnippet = require('raw-loader!../../../../data/snippets/link-wrapper.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Link: Link }}
        code={linkSnippet}
        platform="react"
        gitHubLink="link"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>Determines the type of the link. </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>article</code>
              </li>
              <li>
                <code>implied</code>
              </li>
              <li>
                <code>button</code>
              </li>
              <li>
                <code>wrapper</code>
              </li>
            </List>
            <br />
            <CodeSnippet
              scope={{ React: React, Link: Link, AvatarUser: AvatarUser }}
              code={wrapperSnippet}
              platform="react"
            />
          </PropListItem>

          <PropListItem name="href" types={['string']}>
            <Text>The URL to load when the user clicks the link.</Text>
          </PropListItem>

          <PropListItem name="target" types={['oneOf', 'string']}>
            <Text>
              Where to display the linked URL (same tab, new tab, etc.). Read
              more on the{' '}
              <DocLink href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a">
                MDN site
              </DocLink>
              .
            </Text>
            <List type="unordered">
              <li>
                <code>_self</code> (default)
              </li>
              <li>
                <code>_blank</code>
              </li>
              <li>
                <code>_parent</code>
              </li>
              <li>
                <code>_top</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="download" types={['bool', 'string']}>
            <Text>
              Determines whether to just download the linked content (like an
              image). Pass in a string to prefill the file name in the user's
              Save dialog.
            </Text>
          </PropListItem>

          <PropListItem name="displayType" types={['oneOf', 'string']}>
            <Text>
              When using the wrapper type, this will determine the display of
              the link.
            </Text>
            <List type="unordered">
              <li>
                <code>block</code>
              </li>
              <li>
                <code>inline-block</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this link in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <DocLink href="/guidelines/space" isDesignCodeLink>
                uniCSS space classes
              </DocLink>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
